import roleKeys from '../roleKeys';

export default {
  env: 'production',
  adobe: '//assets.adobedtm.com/3dc13bcabc29/a4d9cd7252f2/launch-428713b61480.min.js',
  'thirdPartyCatalog.searchSku': false,
  [roleKeys.thirdParty.viewCatalog]: true,
  [roleKeys.codeProducts.viewList]: true,
  [roleKeys.codeProduct.viewDetail]: true,
  [roleKeys.partner.viewThirdPartyAgreements]: true,
  [roleKeys.voucherTypes.viewList]: true,
  [roleKeys.voucherBatches.viewList]: true,
  [roleKeys.voucherTypes.viewDetail]: true,
  [roleKeys.voucherBatches.viewDetail]: true,
  [roleKeys.codeProduct.allowVariableLoad]: true,
  i18nDebug: false,
  feesUrl: 'https://learn.playstation.net/csh?context=3P_Fees',
  helpUrl: 'https://learn.playstation.net/csh?context=3P_Welcome',
  downloadHelpUrl: 'https://learn.playstation.net/csh?context=3P_Download',
  startEndDateUrl: 'https://learn.playstation.net/csh?context=Start_End_Date',
  contentPipelineUrl: 'https://publish.playstation.net',
};
